<!--
 * @Author: your name
 * @Date: 2022-04-02 17:29:50
 * @LastEditTime: 2022-04-09 11:42:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\user\components\codebox\index.vue
-->
<template>
  <div class="codebox" @click="showcode" @click.stop>
    <img src="@/assets/images/user/code.png" alt="" class="img">
    <div class="text">关注公众号，各种福利、活动、新品，都在这里！</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showqrcode:true,  // 公众号弹出层
    }
  },
  methods:{
    showcode(){
      this.$emit("showcode")
    }
  },
}
</script>
<style lang="less" scoped>
.codebox{
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  height: 48px;
  margin: 15px 0;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  .img{
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: block;
    margin-right: 8px;
  }
}
</style>