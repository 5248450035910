<template>
  <div class="other">
    <itembox :info="feedback"></itembox>
    <img src="@/assets/images/user/line.png" alt="" class="other-img">
    <itembox :info="my"></itembox>
    <img src="@/assets/images/user/line.png" alt="" class="other-img">
    <itembox :info="set"></itembox>
  </div>
</template>
<script>
import itembox from "@/views/user/components/other/components/index.vue"
export default {
  data() {
    return {
      feedback:{
        title:"意见反馈",
        url:require("@/assets/images/user/serve.png"),
        path:"/feedback"  
      }, // 意见反馈
      my:{
        title:"关于我们",
        url:require("@/assets/images/user/my.png"),
        path:"/aboutus"  
      }, // 意见反馈
      set:{
        title:"设置",
        url:require("@/assets/images/user/setup.png"),
        path:"/setup"  
      }, //意见反馈
    }
  },
  components:{
    itembox
  }
}
</script>
<style lang="less" scoped>
.other{
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 15px;
  // height: 118px;
  .other-img{
    width: 92%;
    margin: 0 auto;
    display: block;
    height: 1px;
  }
}
</style>