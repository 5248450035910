<template>
  <div class="itembox" :style="stlyeobj"  @click="showSwitch">
    <div class="left">
      <div class="title">{{info.title}}</div>
      <div class="content" v-if="info.icon">
        <span class="number">{{info.front}}</span>
        <span class="float">{{info.after}}</span>
        <span class="company">元</span>
      </div>
      <div class="content" v-else>
        <span class="number">{{info.num}}</span>
         <span class="company">台</span>
      </div>
    </div>
    <div class="right">
      <van-icon name="arrow" size="12" color="#1982ff" v-if="info.icon"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stlyeobj:{
        flexBasis:this.info.basie
      }
    }
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  methods:{
    // 如果是需要展示切换设备弹窗
    showSwitch(){
      if(this.info.icon){
        this.$emit("icon")
      }
    }
  },
}
</script>
<style lang="less" scoped>
.itembox{
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: #222;
  box-sizing: border-box;
  padding: 15px;
  .left{
    display: flex;
    flex-direction: column;
    .title{
      color: #707784;
      font-size: 12px;
    }
    .content{
      margin-top: 12px;
      color: #222;
      font-weight: 600;
      font-size: 20px;
      font-family: "bahn";
      .float{
        font-size: 14px;
      }
      .company{
        font-size: 12px;
        transform: scale(0.8);
        transform-origin: left;
        margin-left: 2px;
      }
    }
  }
  .right{
    .right-img{
      width: 10px;
      height: 12px;
    }
  }
}
</style>