<template>
<div class="itembox" @click="Jump">
  <div class="left">
    <img :src="info.url" alt="" class="left-img">
    <div class="title">{{info.title}}</div>
  </div>
  <div class="right">
    <img src="@/assets/images/user/spot.png" alt="" class="img">
  </div>
</div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
  methods:{
    Jump(){
      this.$router.push(this.info.path)
    }
  },
  props:{
    info:{
      type:Object,
      default:() =>{
        return {}
      }
    }
  }
}
</script>
<style lang="less" scoped>
.itembox{
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px;
  .left{
    display: flex;
    justify-items: center;
    align-items: center;
    .title{
      font-size: 14px;
      color: #666666;
      font-weight: 600;
    }
    .left-img{
      margin-right: 12px;
      width: 22px;
      height: 22px;
    }
  }
  .right{
    .img{
      display: block;
      width: 4px;
      height: 14px;
    }
  }  
}
</style>