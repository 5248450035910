<!--
 * @Author: your name
 * @Date: 2022-04-02 17:55:38
 * @LastEditTime: 2022-04-28 10:52:04
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\user\components\bottombox\index.vue
-->
<template>
  <div class="bottombox">
    <p class="pone">我的工具</p>
    <div class="boxinfo">
       <funboxitem v-for="(item,index) in funbox" :key="index" :hownum="funbox.length" :item="item" @nopath="nopath" :bottomtext="'#666666'" :imgInfo="item.imgInfo"></funboxitem>
    </div>
  </div>
</template>
<script>
import funboxitem from "@/views/index/components/funboxitem.vue"
export default {
  data() {
    return {
       funbox:[
        {
          url:require("@/assets/images/user/newbillinfo.png"),
          text:"账单明细",
          path:"/bill",
          imgInfo:{
            width:19+"px",
            height:24+"px",
          }
        },
        {
          url:require("@/assets/images/user/neworder.png"),
          text:"订购订单",
          path:"/order",
          imgInfo:{
            width:19+"px",
            height:24+"px",
          }
        },
        {
          url:require("@/assets/images/user/server.png"),
          text:"微信客服",
          imgInfo:{
            width:26+"px",
            height:24+"px",
          }
        },
        {
          url:require("@/assets/images/user/yhq.png"),
          text:"优惠券",
          imgInfo:{
            width:28+"px",
            height:24+"px",
          },
          path:`/coupon`
        } 
        // {
        //   url:require("@/assets/images/user/setup.png"),
        //   text:"设置",
        //   imgInfo:{
        //     width:24+"px",
        //     height:24+"px",
        //   },
        //   path:"/setup"
        // }    
      ], // 功能区域列
    }
  },
  components:{
    funboxitem
  },
  methods:{
    nopath(){
      this.$emit("nopath")
    }
  }
}
</script>
<style lang="less" scoped>
.bottombox{
  background-color: #fff;
  width: 100%;
  border-radius: 6px;
  height: 118px;
  margin-top: 15px;
  .pone{
    color: #222;
    font-weight: 600;
    font-size: 14px;
    padding-top: 14px;
    margin-left: 16px;
  }
  .boxinfo{
    display: flex;
    margin-top: 22px;
    /deep/.text{
      color: #666;
    }
  }
}
</style>