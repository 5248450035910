<!--
 * @Author: your name
 * @Date: 2022-04-02 15:44:38
 * @LastEditTime: 2022-06-22 13:49:33
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\user\components\index.vue
-->
<template>
<div>
  <div class="userbox">
    <div class="imagebox">
      <img :src="userinfo.headimgurl" alt="" class="img"  v-if="userinfo.headimgurl">
    </div>
    <div class="newright">
      <div class="topnew">{{userinfo.phone}}
        <!-- <img src="@/assets/images/user/write.png" alt="" class="top-img"> -->
      </div>
      <div class="bottom">
        <div class="noname" v-if="userinfo.buy_id <= 0" @click="buyMeal">尚未购套餐，去购买<van-icon name="arrow" /></div>
        <img src="@/assets/images/user/status.png" alt="" class="bottom-img" v-if="packShow && userinfo.buy_id > 0">
        <img src="@/assets/images/user/noispick.png" alt="" class="bottom-img" @click="goPick" v-if="!packShow && userinfo.buy_id > 0">
      </div>
    </div>
  </div>
  <!-- <div class="infobox">
    <funboxitem v-for="(item,index) in infoboxlist" :key="index" :hownum="infoboxlist.length" :item="item" :textcolor="'#222222'" :bottomtext="'#707784'">
      <div class="text-top">
        <span class="sapn">{{item.money == ""&& index !=2?"--":item.money}}</span>
        <span class="company" :class="{isstatus: item.company == 'G'?false:true}">{{item.company}}</span>
      </div>
    </funboxitem>
  </div> -->
</div>
</template>
<script>
import funboxitem from "@/views/index/components/funboxitem.vue"
export default {
  data() {
    return{
      infoboxlist:[
        {
          text:"设备余额",
          money:"",
          company:"元",
        },
        {
          text:"绑定设备",
          money:"",
          company:"台"
        }
        // {
        //   text:"设备剩余",
        //   money:"",
        //   company:"",
        //   isloading:true,
        //   color:"#222",
        // }
      ],  // 用户信息,模块
      packShow:false, // 是否官方实名过
    }
  },
  watch:{
    userinfo:{
      handler(nvalue,ovalue){
        this.infoboxlist[0].money = nvalue.c_balance
        this.infoboxlist[1].money = nvalue.devices
      },
      deep:true,
      immediate:true
    },
    // surflow:{
    //   handler(nvalue,ovalue){
    //     if(nvalue.surplus){
    //        this.infoboxlist[2].money = nvalue.surplus
    //        this.infoboxlist[2].company = nvalue.surplus_unit
    //        this.infoboxlist[2].isloading = false
    //     }else{
    //       this.infoboxlist[2].money = ""
    //       this.infoboxlist[2].company = ""
    //       this.infoboxlist[2].isloading = true
    //     }
    //     // this.infoboxlist[2].money = nvalue.surplus
    //     // this.infoboxlist[2].company = nvalue.surplus_unit
    //     // this.infoboxlist[2].isloading = false
    //   },
    //   deep:true,
    // //   immediate:true
    // },
    "userinfo.ispick":{
      handler(newV,oldV){
        if(newV == 1){
          this.packShow = false
        }else{
          this.packShow = true
        }
      }
    }
  },
  props:{
    userinfo:{
      type:Object,
      default:() =>{
        return {}
      }
    },
    // surflow:{
    //   type:Object,
    //   default:()=>{
    //     return {}
    //   }
    // },
  },
  components:{
    funboxitem
  },
  methods:{
    // 点击购买套餐
    buyMeal(){
      this.$emit("buyMeal")
    },
    goPick(){
      if(this.userinfo.buy_id != 0 ){
        //  window.location.href="http://iot.yunruiot.com/certif_entry.html?userCode=r/s9Tc00hjiKcR13MIjZHg==&passWord=9u8VuY1xbez6r6k/BBnLlw==&phone="+this.userinfo.phone+"&accessNbr="+this.userinfo.new_iccid
        window.location.href = this.userinfo.real_url
      }else{
        this.$toast("请先购买套餐")
        setTimeout(() =>{
          this.$router.push("/selectpack")
        },2000)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.userbox{
  padding: 10px 0 0 26px;
  display: flex;
  .imagebox{
    width: 64px;
    height: 64px;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 2px;
    .img{
      width: 60px;
      height: 60px;
      display: block;
      border-radius: 7px;
    }
  }
  .newright{
    height: 64px;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    .topnew{
      display: flex;
      font-size: 24px;
      color: #222222;
      font-weight: 600;
      align-items: center;
      justify-items: center;
      font-family: "bahn";
      .top-img{
        width: 12px;
        height: 12px;
        display: block;
        margin-left: 12px;
      }
    }
    .bottom{
       margin-top: 8px;
       .noname{
         display: flex;
         color: #f13756;
         font-size: 12px;
         font-weight: 400;
         transform-origin: left;
         align-items: center;
         justify-content: center;
         letter-spacing: 1px;
         transform: scale(0.8);
         /deep/.van-icon{
           margin-left: 10px;
         }
       }
      .bottom-img{
         height: 18px;
         width: 100px;
         display: block;
      }
    }
  }
}
.infobox{
  width: 100%;
  height: 40px;
  display: flex;
  margin-top: 30px;
  /deep/.text{
    // color: #707784;
    margin-top: 8px;
  }
  /deep/.isloading{
    color: #222;
  }
  .text-top{
    .company{
      font-size: 12px;
      color: #222;
      font-weight: 600;
    }
    .isstatus{
      transform: scale(0.8);
    }
    .sapn{ 
      font-size: 24px;
      color: #222;
      font-weight: 600;
      font-family: "bahn";
      }
    }
}
</style>